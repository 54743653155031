import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

import {
  EditableImage,
  EditableMarkdown,
  SponsorsSection,
} from "@app/components";
import { isEmpty } from "@app/utils";

export interface PreparePageData {
  title: any;
  body: any;
  images: { image: any }[];
}

export const PreparePage = ({
  data,
  isEdit,
}: {
  data: PreparePageData;
  isEdit: boolean;
}) => {
  if (!isEdit) {
    data = {
      ...(data as any).content.childMarkdownRemark.frontmatter,
      body: (data as any).content.childMarkdownRemark.html,
    };
  }

  return (
    <>
      {/* title */}
      {data.title && (
        <section className="py-5 text-center">
          <Container>
            <Row>
              <Col>
                <h1 className="text-primary">{data.title}</h1>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className="py-5">
        <Container>
          <Row>
            {/* body */}
            <Col md={6}>
              <EditableMarkdown isEdit={isEdit} markdown={data.body} />
            </Col>

            {/* images */}
            {!isEmpty(isEdit, data.images) && (
              <Col md={6}>
                {data.images.map((i, index) => (
                  <div key={"image" + index} className="mb-4">
                    <EditableImage isEdit={isEdit} image={i.image} alt="" />
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </Container>
      </section>

      {/* sponsors */}
      {!isEdit && <SponsorsSection />}
    </>
  );
};

export default PreparePage;

export const query = graphql`
  query {
    content: file(
      sourceInstanceName: { eq: "content" }
      relativeDirectory: { eq: "pages" }
      name: { eq: "prepare" }
    ) {
      childMarkdownRemark {
        html
        frontmatter {
          seo_title
          seo_description
          title
          images {
            image {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
      }
    }
  }
`;
